import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, styled } from '../../../../../../reakt/core/dist/index';

const styles = {
  h1: css`
    margin: 0 0 20px;
    font-size: 24px;
    font-weight: bold;
  `,
  h2: css`
    margin: 0 0 20px;
    font-size: 20px;
    font-weight: bold;
  `,
  h3: css`
    margin: 0 0 15px;
    font-size: 18px;
    font-weight: normal;
    font-style: italic;
  `,
  h4: css`
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 10px;
  `,
  h5: css`
    font-size: 14px;
    font-weight: normal;
    margin: 0 0 10px;
  `,
  h6: css`
    font-size: 12px;
    font-weight: normal;
    margin: 0 0 10px;
  `
};

const headingMixin = (Base, level) => {
  return class extends Base {
    static propTypes = {
      children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
      ]).isRequired,
      level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
      icon: PropTypes.object
    };
    static defaultProps = { level };

    render() {
      const { props } = this;
      const headingLevel = `h${props.level}`;
      const styledComponent = styled[headingLevel];
      const Element = styledComponent`${styles[headingLevel]}`;

      return (
        <Element>{props.children}</Element>
      );
    }
  };
};

export class H1 extends headingMixin(PureComponent, 1) {}
export class H2 extends headingMixin(PureComponent, 2) {}
export class H3 extends headingMixin(PureComponent, 3) {}
export class H4 extends headingMixin(PureComponent, 4) {}
export class H5 extends headingMixin(PureComponent, 5) {}
export class H6 extends headingMixin(PureComponent, 6) {}
