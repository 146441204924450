/* global GATSBY_GA_ID, GATSBY_SITE */
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import * as ReactGA from 'react-ga';
import awsconfig from '../../aws-exports';
import { click } from '../../graphql/mutations';
import { getDailyCount, getTotalCount } from '../../graphql/queries';
import { setCookie, getCookie } from '../../../../../reakt/core/dist/index';

Amplify.configure(awsconfig);

if (GATSBY_GA_ID) {
  ReactGA.initialize(GATSBY_GA_ID);
}

class ClickCounter extends PureComponent {

  static propTypes = {
    children: PropTypes.func.isRequired
  };

  state = {
    todayCount: 0,
    totalCount: 0,
    animate: false,
    disabled: !!getCookie('clicked')
  };

  componentDidMount() {
    this.getCounts();
  }

  async getCounts() {
    const todayCountPromise = await API.graphql(graphqlOperation(getDailyCount, {
      siteId: GATSBY_SITE,
      date: this.formatDate(new Date())
    }));

    API.graphql(graphqlOperation(getTotalCount, {
      siteId: GATSBY_SITE
    })).then(result => this.setState(state => ({
      todayCount: todayCountPromise.data.getDailyCount ? todayCountPromise.data.getDailyCount.dailyCount : 0,
      totalCount: result.data.getTotalCount,
      animate: state.totalCount === 0 && result.data.getTotalCount > 0
    })));
  }

  formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
  };

  increment = (event) => {
    if (event) event.preventDefault();

    if (GATSBY_GA_ID) {
      ReactGA.event({
        category: 'User',
        action: 'Prayer'
      });
    }

    if (!this.state.disabled) {
      API.graphql(graphqlOperation(click, {
        siteId: GATSBY_SITE
      }));

      this.setState({
        todayCount: this.state.todayCount + 1,
        totalCount: this.state.totalCount + 1,
        disabled: true
      });

      setCookie('clicked', 'y', 5);

      setInterval(() => {
        if (getCookie('clicked') !== 'y') {
          this.setState({
            disabled: false
          });
        }
      }, 1000);
    }
  };

  render() {
    const { props, state } = this;

    return props.children({
      todayCount: state.todayCount,
      totalCount: state.totalCount,
      animate: state.animate,
      disabled: state.disabled,
      increment: this.increment
    });
  }
}

export default ClickCounter;
