import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const IconClose = styled.span`
  ${({ dropShadow, color, size, position }) => css`
    position: absolute;
    top: -${size / 2}px;
    ${position}: -${size / 2}px;
    display: block;
    width: ${size}px;
    height: ${size}px;
    line-height: ${size - 3}px;
    text-align: center;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
    border-radius: 50%;
    background-color: #fff;
    
    ${dropShadow && css`
      box-shadow: 0 0 5px #000;
    `};
    
    &:after {
      content: 'x';
      font-size: ${size - 6}px;
      font-weight: bold;
    }
    
    @media only screen and (max-width: 420px) {
      top: 0;
      right: 0;
      box-shadow: none;
      &:after {
        font-size: 25px;
        font-weight: normal;
      }
    }
  `};
 
`;

IconClose.propTypes = {
  position: PropTypes.oneOf(['left', 'right']),
  size: PropTypes.number,
  color: PropTypes.string,
  dropShadow: PropTypes.bool
};

IconClose.defaultProps = {
  position: 'right',
  size: 20,
  color: '#fff',
  dropShadow: false
};

export default IconClose;
