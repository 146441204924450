import color from 'color';
import { css } from 'styled-components';

const theme = {
  mainColor: color('#dc3401'),
  bgColor: color('#fff7f7')
};

export const fontContent = css`
  font-family: Raleway, 'Trebuchet MS', Arial, Helvetica, sans-serif;
  color: #333;
`;

export const fontHeader = css`
  font-family: 'Mina', 'Trebuchet MS', Arial, Helvetica, sans-serif;
`;

export const ripple = css`
  background-position: center;
  transition: background 0.8s;
  
  &:hover {
    background: ${theme.mainColor.string()}
      radial-gradient(circle, transparent 1%, ${theme.mainColor.string()} 1%) center/15000%;
  }
  &:active {
    background-color: ${theme.mainColor.lighten(0.2).string()};
    background-size: 100%;
    transition: background 0s;
  }
`;

export const breakpointMobile = '@media only screen and (max-width: 599px)';

export default theme;
