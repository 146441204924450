import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import IconHamburger from '../IconHamburger/index';

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  
  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
`;

const MenuToggle = props => (
  <Wrapper size={props.size} onClick={props.onClick} className={props.className}>
    <IconHamburger {...props} />
  </Wrapper>
);

MenuToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  size: PropTypes.number
};

MenuToggle.defaultProps = {
  size: 20
};

export default MenuToggle;
