import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { styled } from '../../../../../../reakt/core/dist/index';
import Link from '../Link/index';

function List(props) {
  const ListWrapper = styled[props.ordered ? 'ol' : 'ul']`
    margin: 0 0 20px;
  `;
  const ListItem = styled.li`
    margin-bottom: 10px;
  `;

  return (
    <ListWrapper>
      {props.list.map(item => (
        <ListItem key={item.title}>
          {props.itemRenderer(this, item, props)}
        </ListItem>
      ))}
    </ListWrapper>
  );
}

List.itemRenderer = (component, item, props) => {
  if (item.link) {
    return (
      <Link url={item.link} onClick={() => props.onLinkClick()}>
        {item.icon}
        {item.title}
      </Link>
    );
  }

  return (
    <Fragment>
      {item.icon}
      {item.title}
    </Fragment>
  );
};

List.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string,
      icon: PropTypes.string
    })
  ).isRequired,
  itemRenderer: PropTypes.func,
  onLinkClick: PropTypes.func,
  ordered: PropTypes.bool,
  css: PropTypes.string
};

List.defaultProps = {
  itemRenderer: List.itemRenderer,
  onLinkClick: () => {},
  ordered: false,
  css: undefined
};

export default List;
