import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Toggleable extends PureComponent {

  static propTypes = {
    children: PropTypes.func
  };

  container = React.createRef();

  state = {
    isOpened: false
  };

  componentDidMount() {
    if (typeof document !== 'undefined') {
      document.addEventListener('click', this.handleOutsideClick);
    }
  }

  componentWillUnmount() {
    if (typeof document !== 'undefined') {
      document.removeEventListener('click', this.handleOutsideClick);
    }
  }

  open = event => {
    if (event) event.preventDefault();

    this.setState({ isOpened: true });
  };

  close = event => {
    if (event) event.preventDefault();

    this.setState({ isOpened: false });
  };

  handleOutsideClick = (event) => {
    if (!this.container.current.contains(event.target)) {
      this.close();
    }
  };

  render() {
    const { props, state } = this;

    return props.children({
      innerRef: this.container,
      toggle: state.isOpened ? this.close : this.open,
      isOpened: state.isOpened
    });
  }
}

export default Toggleable;
