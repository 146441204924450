import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Drawer = styled.div`
  margin-top: 2px;
  background-color: #fff;
  position: absolute;
  border-radius: 0 3px 3px 0;
  
  ${({ border, width, position, opened, dropShadow, breakpoint }) => css`
    width: ${width}px;
    
    ${!breakpoint && css`
      transition: ${position} .5s ease;
      ${position}: ${opened ? '-5px' : `-${width + 5}px`};
    `};
    
    ${breakpoint && css`
      @media only screen and (min-width: ${breakpoint}px) {
        left: -210px;
      }
      
      @media only screen and (max-width: ${breakpoint - 1}px) {
        transition: ${position} .5s ease;
        ${position}: ${opened ? '-5px' : `-${width + 5}px`};
      }
    `};
    
    ${border && css`
      border: ${border.width}px solid ${border.color}
    `};
    
    ${dropShadow && css`
      box-shadow: 0 0 4px #333;
    `};
  `};
`;

Drawer.propTypes = {
  border: PropTypes.shape({
    width: PropTypes.number,
    color: PropTypes.string
  }),
  breakpoint: PropTypes.number,
  width: PropTypes.number,
  position: PropTypes.oneOf(['left', 'right'])
};

Drawer.defaultProps = {
  border: undefined,
  breakpoint: undefined,
  width: 200,
  position: 'left'
};

export default Drawer;
