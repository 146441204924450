import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { P } from '../common/Text/index';
import Toggleable from '../web/Toggleable/index';
import Drawer from '../web/Drawer/index';
import Space from '../common/Space/index';
import Popup from '../common/Popup/index';
import Overlay from '../common/Overlay/index';
import IconClose from '../web/IconClose/index';
import ClickButton from '../ClickButton/index';

import theme, { breakpointMobile } from '../../theme';

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  margin-top: 90px;
  cursor: pointer;
  
  ${breakpointMobile} {
    display: none;
  }
`;

const Title = styled.div`
  position: absolute;
  right: 68px;
  top: 76px;
  width: 230px;
  height: 67px;
  line-height: 73px;
  padding: 0 20px;
  margin-right: 50%;
  transform: rotate(90deg);
  border-radius: 0 0 20px 20px;
  font-size: 22px;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
  background-color: ${theme.mainColor.string()};
  background: linear-gradient(
    ${theme.mainColor.string()},
    ${theme.mainColor.darken(0.18).string()}
  );
  color: #fff;
  box-shadow: 0 2px 4px #333;
`;

const WrapperMobile = styled.div`
  width: 350px;
  margin-top: 15px;
  
  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

const Korunka = ({ title, content }) => (
  <Toggleable>
    {({ innerRef, isOpened, toggle }) => (
      <div ref={innerRef}>
        <Wrapper>
          <Drawer
            opened={isOpened}
            width={300}
            position="right"
            border={{ width: 5, color: theme.mainColor.string() }}
            dropShadow
          >
            <Title onClick={toggle}>
              {title}
            </Title>

            <Space space="15">
              {content.map((line, i) => (
                <Space space="0 0 10" key={i}>
                  <P>{line}</P>
                </Space>
              ))}
            </Space>
          </Drawer>
        </Wrapper>

        <WrapperMobile>
          <ClickButton
            onClick={toggle}
            color={theme.mainColor}
            type="button"
          >
            {title}
          </ClickButton>

          {isOpened &&
            <Overlay onClick={toggle}>
              <Popup width={400}>
                <IconClose onClick={toggle} size={22} dropShadow />
                <Space space="15" onClickCapture={e => e.stopPropagation()}>
                  {content.map((line, i) => (
                    <Space space="0 0 10" key={i}>
                      <P>{line}</P>
                    </Space>
                  ))}
                </Space>
              </Popup>
            </Overlay>
          }
        </WrapperMobile>
      </div>
    )}
  </Toggleable>
);

Korunka.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired
};

export default Korunka;
