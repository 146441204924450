import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '../../../../../../reakt/core/dist/index';

function Link(props) {

  const A = styled.a`
    text-decoration: none;
    
    :hover {
      text-decoration: underline;
    }
  `;

  return (
    <A href={props.url} onClick={e => props.onClick(e)}>
      {props.children}
    </A>
  );
}

Link.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

Link.defaultProps = {
  onClick: () => {}
};

export default Link;
