import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MenuWrapper = styled.ul`
  margin: 0;
  padding: 0;
  background-color: #333;
`;

const MenuLink = styled.a`
  display: block;
  padding: 12px 15px 10px;
  border-bottom: 1px solid #555;
  text-decoration: none;
  color: #fff;
  
  :hover {
    background-color: #444;
  }
  
`;

const MenuItem = styled.li`
  list-style: none;
  
  :last-child ${MenuLink} {
    border: none;
  }
`;

const SiteMenuContent = props => (
  <MenuWrapper>
    {props.items.map(item => (
      <MenuItem key={item.title}>
        <MenuLink href={item.link} onClick={() => props.toggle()}>
          {item.title}
        </MenuLink>
      </MenuItem>
    ))}
  </MenuWrapper>
);

SiteMenuContent.propTypes = {
  toggle: PropTypes.func
};

SiteMenuContent.default = {
  toggle: () => {}
};

export default SiteMenuContent;
