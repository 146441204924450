/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const click = /* GraphQL */ `
  mutation click($siteId: Lang!) {
    click(siteId: $siteId) {
      siteId
      dailyCount
    }
  }
`;
