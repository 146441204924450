import React from 'react';
import PropTypes from 'prop-types';
import { styled, css } from '../../../../../../reakt/core/dist/index';

const bold = css`
  font-weight: bold;
`;

const italic = css`
  font-style: italic;
`;

const Text = styled.span`
  ${props => props.bold && bold}
  ${props => props.italic && italic}
`;

export const P = styled.p`
  margin: 0;
`;

export const Bold = props => <Text bold>{props.children}</Text>;
export const Italic = props => <Text italic>{props.children}</Text>;
export const Quote = props => <Italic>„{props.children}“</Italic>;

Text.propTypes = {
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  quote: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

Text.defaultProps = {
  bold: false,
  italic: false,
  quote: false
};

Bold.defaultProps = Text.defaultProps;
Italic.defaultProps = Text.defaultProps;
Quote.defaultProps = Text.defaultProps;

Text.displayName = 'Text';
P.displayName = 'P';
Bold.displayName = 'Bold';
Italic.displayName = 'Italic';
Quote.displayName = 'Quote';

export default Text;
