import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MenuToggle from '../web/MenuToggle/index';
import IconHamburger from '../web/IconHamburger/index';
import Drawer from '../web/Drawer/index';
import Toggleable from '../web/Toggleable/index';
import SiteMenuContent from './SiteMenuContent';
import theme from '../../theme';

const MenuToggleStyled = styled(MenuToggle)``;

const Wrapper = styled.div`
  position: fixed;
  top: 5px;
  
  padding-left: 5px;
  z-index: 1;
  
  @media only screen and (max-width: 1359px) {
  
    :before {
      content: '';
      position: absolute;
      left: -255px;
      z-index: 1;
      display: block;
      width: 250px;
      height: 350px;
      background: #333;
    }
  }
  
  @media only screen and (min-width: 1359px) {
    margin-top: 26px;
    
    ${MenuToggleStyled} {
      display: none;
    }
  }
  
  @media only screen and (min-width: 600px) and (max-width: 1359px) {
    ${IconHamburger} {
      &,
      &:before,
      &:after {
        background-color: ${theme.mainColor.lighten(0.2).string()}
      }
    }
  }
  
  @media only screen and (max-width: 599px) {
    left: 0;
    top: 9px;
    padding-left: 9px;
  }
 
`;

const SiteMenu = props => (
  <Toggleable>
    {({ innerRef, isOpened, toggle }) => (
      <Wrapper ref={innerRef}>
        <MenuToggleStyled onClick={toggle} size={30} color="#fff" />

        <Drawer opened={isOpened} breakpoint={1360} dropShadow>
          <SiteMenuContent items={props.items} toggle={toggle} />
        </Drawer>
      </Wrapper>
    )}
  </Toggleable>
);

SiteMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string,
      icon: PropTypes.string
    })
  ).isRequired
};

export default SiteMenu;
