/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDailyCount = /* GraphQL */ `
  query getDailyCount($siteId: Lang!, $date: String!) {
    getDailyCount(siteId: $siteId, date: $date) {
      dailyCount
    }
  }
`;

export const getTotalCount = /* GraphQL */ `
  query getTotalCount($siteId: Lang!) {
    getTotalCount(siteId: $siteId)
  }
`;
