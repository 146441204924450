import React, { PureComponent } from 'react';
import { getCookie, setCookie, styled } from '../../../../../reakt/core/dist/index';
import theme from '../../theme';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: #fff;
  font-size: 12px;
  box-shadow: 0 0 5px #999;
`;

const Inside = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.5;
`;

const Button = styled.button`
  background-color: ${theme.mainColor.string()};
  color: #fff;
  border: none;
`;

export default class CookiesInfo extends PureComponent {

  state = {
    hideCookieInfo: !!getCookie('hideCookieInfo')
  };

  closeInfo = (e) => {
    if (e) e.preventDefault();

    setCookie('hideCookieInfo', 'y', 43200);

    this.setState({
      hideCookieInfo: true
    });
  };

  render() {
    if (this.state.hideCookieInfo) {
      return <noscript />;
    }

    return (
      <Wrapper>
        <Inside>
          <span dangerouslySetInnerHTML={{ __html: this.props.text }}/> &nbsp;
          <Button onClick={this.closeInfo}>OK</Button>
        </Inside>
      </Wrapper>
    );
  }
}
