import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ripple, fontHeader, breakpointMobile } from '../../theme';

const ClickButton = styled.button`
  padding: 15px 25px;
  margin-right: 50%;
  border: none;
  border-radius: 15px;
  font-size: 22px;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  
  ${fontHeader};
  
  &:focus {
    outline: none;
  }
  
  ${({ color, disabled }) => css`
    ${disabled && css`
      background-color: #888;
    `};
    
    ${!disabled && css`
      ${ripple};
      
      background-color: ${color.string()};
      background: linear-gradient(
        ${color.string()},
        ${color.darken(0.18).string()}
      );
    `};
  `};
 
  ${breakpointMobile} {
    font-size: 18px;
    margin: 0;
  }
`;

ClickButton.propTypes = {
  color: PropTypes.shape({})
};

ClickButton.defaultProps = {
  color: '#fff'
};

export default ClickButton;
