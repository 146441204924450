import { styled } from '../../../../../reakt/core/dist/index';

export default styled.div`
  font-size: 70px;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 0 10px #000;
  
  @media (max-width: 950px) {
    font-size: 60px;
  }
  
  @media (max-width: 850px) {
    font-size: 53px;
  }
  
  @media (max-width: 750px) {
    font-size: 45px;
  }
  
  @media (max-width: 700px) {
    font-size: 38px;
  }
  
  @media (max-width: 599px) {
    font-size: 20px;
    display: inline-block;
    text-shadow: none;
  }
`;
