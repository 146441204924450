import PropTypes from 'prop-types';
import { css, styled } from '../../../../../../reakt/core/dist/index';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  
  ${({ color }) => css`
    background-color: ${color};
  `};
`;

Overlay.propTypes = {
  color: PropTypes.string,
  opacity: PropTypes.number
};

Overlay.defaultProps = {
  color: 'rgba(0, 0, 0, 0.7)',
  opacity: 0.7
};

export default Overlay;
