import PropTypes from 'prop-types';
import { css, styled } from '../../../../../../reakt/core/dist/index';

const Popup = styled.div`
  position: relative;
  padding: 15px;
  background-color: #fff;
  border-radius: 4px;
  max-width: 100%;
  
  ${({ width }) => css`
    width: ${width}px;
  `}; 
`;

Popup.propTypes = {
  width: PropTypes.number
};

Popup.defaultProps = {
  width: 300
};

export default Popup;
