import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const IconInfo = styled.span`
  ${({ color, size, dropShadow }) => css`
    display: block;
    width: ${size}px;
    height: ${size}px;
    line-height: ${size -3}px;
    border: ${size / 12}px solid ${color};
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    
    ${dropShadow && css`
      box-shadow: 0 0 5px #000;
    `};
    
    &:after {
      content: 'i';
      font-size: ${size - 6}px;
      font-family: serif;
      font-weight: bold;
      color: ${color};
      
      ${dropShadow && css`
        text-shadow: 0 0 5px #000;
      `}
    }
  `};
 
`;

IconInfo.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  dropShadow: PropTypes.bool
};

IconInfo.defaultProps = {
  size: 20,
  color: '#fff',
  dropShadow: false
};

export default IconInfo;
