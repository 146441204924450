import { createElement } from 'react';

const jsonToReactElement = (types, { id: key, t: type, p: props, c: children }) => {
  
  return createElement(
    type.toLowerCase() === type ? type : types[type] || 'div',
    { ...props, key },
    Array.isArray(children)
      ? children.map(obj => jsonToReactElement(types, obj))
      : children
  );
};

export default jsonToReactElement;
