import PropTypes from 'prop-types';
import { styled, css, parseSideValues } from '../../../../../../reakt/core/dist/index';

const Space = styled.div`
  ${({ space, spaceMobile, spaceTop, spaceRight, spaceBottom, spaceLeft, theme }) => {
    const { top, right, bottom, left } = parseSideValues(space);
    const {
      top: topMobile,
      right: rightMobile,
      bottom: bottomMobile,
      left: leftMobile
    } = parseSideValues(spaceMobile);
    
    const topValue = spaceTop === undefined && top === undefined
      ? theme.space : spaceTop || top || 0;
    const rightValue = spaceRight === undefined && right === undefined
      ? theme.space : spaceRight || right || 0;
    const bottomValue = spaceBottom === undefined && bottom === undefined
      ? theme.space : spaceBottom || bottom || 0;
    const leftValue = spaceLeft === undefined && left === undefined
      ? theme.space : spaceLeft || left || 0;

    const topValueMobile = topMobile || 0;
    const rightValueMobile = rightMobile || 0;
    const bottomValueMobile = bottomMobile || 0;
    const leftValueMobile = leftMobile || 0;
    
    return css`
      padding: ${topValue}px ${rightValue}px ${bottomValue}px ${leftValue}px;
      
      ${spaceMobile && css`
        @media only screen and (max-width: 599px) {
          padding: ${topValueMobile}px ${rightValueMobile}px
            ${bottomValueMobile}px ${leftValueMobile}px
        }
      `};
      
    `;
  }}
}
`;

const PropTypesNumberOrString = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string
]);

Space.propTypes = {
  spaceTop: PropTypesNumberOrString,
  spaceRight: PropTypesNumberOrString,
  spaceBottom: PropTypesNumberOrString,
  spaceLeft: PropTypesNumberOrString,
  /**
   * Supported format is the same as shorthand values for padding in CSS:
   * "10 20 30 40" means top, right, bottom, left
   * "10 20 30"    means top, right and left, bottom
   * "10 20"       means top and bottom, left and right
   * "10"          means top and right and bottom and left
   */
  space: PropTypes.string,
  spaceMobile: PropTypes.string
};

Space.defaultProps = {
  spaceTop: undefined,
  spaceRight: undefined,
  spaceBottom: undefined,
  spaceLeft: undefined,
  space: undefined,
  spaceMobile: undefined
};

export default Space;
