import React from 'react';
import { css, styled } from '../../../../../../reakt/core/dist/index';

const StyledFlex = styled.div`
  display: flex;
  flex-direction: row;
  
  ${props => props.alignCenter && css`
    align-items: center;
  `};
  
  ${props => props.notOnMobile && css`
    @media only screen and (max-width: 600px) {
      display: block;
      text-align: center;
    }
  `};
`;

const StyledItem = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  
  @media only screen and (max-width: 600px) {
    display: inline-block;
    vertical-align: middle;
  }
`;

const Flex = props => (
  <StyledFlex alignCenter={props.alignCenter} notOnMobile={props.notOnMobile}>
    {React.Children.map(props.children, (child, i) => {
      const notWrappedComponents = ['Image'];

      if (notWrappedComponents.indexOf(child.type.displayName) > -1) {
        return child;
      }

      return (
        <StyledItem key={i} notOnMobile={props.notOnMobile}>{child}</StyledItem>
      );
    })}
  </StyledFlex>
);

export default Flex;
