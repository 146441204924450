import React from 'react';

import Text, { P as Paragraph } from './Text';

export const Bold = props => <Text bold dangerouslySetInnerHTML={{ __html: props.children }} />;
export const Italic = props => <Text italic dangerouslySetInnerHTML={{ __html: props.children }} />;
export const Quote = props => <Text italic dangerouslySetInnerHTML={{ __html: `„${props.children}“` }} />;
export const P = props => <Paragraph dangerouslySetInnerHTML={{ __html: props.children }} />;

export default Text;
