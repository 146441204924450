export default function parseSideValues(stringValues = '') {
  const split = stringValues.split(' ');

  return {
    top: parseInt(split[0] || 0, 10),
    right: parseInt(split[1] || split[0] || 0, 10),
    bottom: parseInt(split[2] || split[0] || 0, 10),
    left: parseInt(split[3] || split[1] || split[0] || 0, 10)
  };
}
