/* global GATSBY_SITE */
import React, { Fragment, PureComponent } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import CountUp from 'react-countup';
import SiteName from './components/SiteName/index';
import Space from './components/common/Space/index';
import List from './components/common/List/index';
import { P } from './components/common/Text/index';
import { H1, H2, H3 } from './components/common/Heading/index';
import Flex from './components/common/Flex/index';
import Overlay from './components/common/Overlay/index';
import Popup from './components/common/Popup/index';
import IconInfo from './components/web/IconInfo/index';
import IconClose from './components/web/IconClose/index';
import Toggleable from './components/web/Toggleable/index';

import SiteMenu from './components/SiteMenu/index';
import Korunka from './components/Korunka/index';
import ClickCounter from './components/ClickCounter/index';
import ClickButton from './components/ClickButton/index';
import CookiesInfo from './components/CookiesInfo/index';

import theme, { fontHeader, fontContent, breakpointMobile } from './theme';
import logo from './static/logo.svg';
import bg from './static/bg.jpg';

const data = require(`./data/site.${GATSBY_SITE}.json`);

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Mina:400&subset=latin-ext');
  @import url('https://fonts.googleapis.com/css?family=Raleway:400,700&subset=latin-ext');

  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    background: #333;
    font-size: 16px;
    
    ${fontContent};
    
    section h1 {
      ${fontHeader};
      border-bottom: 8px solid ${theme.mainColor.lighten(1.1).string()};
      
      ${breakpointMobile} {
        padding-top: 50px !important;
        margin-top: -20px !important;
      }
    }
    
    * {
      box-sizing: border-box;
    }
  }
`;

const Page = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0px 0px 10px #000;
  background-color: ${theme.bgColor.string()};
  
  @media only screen and (min-width: 1024px) {
    max-width: 944px;
  }
`;

const Header = styled.header`
  position: relative;
  min-height: 683px;
  background:  url(${bg}) bottom left no-repeat;
    
  ${fontHeader}
  
  ${breakpointMobile} {
    min-height: 530px;
    padding-top: 70px;
    background-position: bottom center;
  }
`;

const Logo = styled.img`
  width: 120px;
  height: 107px;
  
  ${breakpointMobile} {
    width: 35px;
    height: 31px;
    margin-right: 15px;
  }
`;

const MobileHeader = styled.div`
  ${breakpointMobile} {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    padding: 0 50px;
    background-color: ${theme.mainColor.string()}
  }
`;

const Slogan = styled.div`
  padding: 15px 40px 10px;
  text-align: center;
  font-size: 20px;
  text-shadow: 0 0 1px #000;
  background: ${theme.mainColor.alpha(0.7).string()};
  color: #fff;
  
  strong {
    display: block;
    margin-top: 20px;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: normal;
  }
  
  ${breakpointMobile} {
    padding: 10px 15px 8px;
    margin-top: 10px;
    font-size: 16px;
    
    strong {
      margin-top: 10px; 
      font-size: 18px;
    }
  }
`;

const Help = styled.div`
  position: absolute;
  top: -15px;
  right: -15px;
`;

const counts = css`
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 1.2;
  text-shadow: 0 0 8px #000;
  
  ${breakpointMobile} {
    font-size: 18px;
  }
`;

const CountToday = styled.div`
  ${counts};
`;

const CountTotal = styled.div`
  ${counts};
  
  margin: 15px 0;
`;

const CountTodayNumber = styled.div`
  font-size: 60px;
  
  ${breakpointMobile} {
    font-size: 45px;
  }
`;

const ClickCounterWrapper = styled.div`
  @media only screen and (min-width: 600px) {
    max-width: 370px;
  }
`;

const Footer = styled.footer`
  background-color: #333;
  padding: 20px;
  text-align: center;
  color: #fff;
  
  a {
    color: #fff;
    text-decoration: none;
    
    :hover {
      text-decoration: underline;
    }
  }
  
  p {
    margin-top: 10px;
    font-size: 14px;
    color: #aaa;
    
    a {
      color: #aaa;
    }
  }
`;

class KorunkaNaCestu extends PureComponent {

  renderSection(section, level = 1) {
    if (!section.content) return <noscript key={level} />;

    let Heading = null;

    switch (level) {
      case 2: {
        Heading = H2;
        break;
      }
      case 3: {
        Heading = H3;
        break;
      }
      case 1:
      default: {
        Heading = H1;
        break;
      }
    }

    return (
      <section id={section.link ? section.link.replace('#', '') : undefined} key={section.title}>
        <Space space="30 0 0" spaceMobile="0">
          <Heading>
            {section.title}
          </Heading>

          {section.content.map((line, i) => (
            <div key={i}>
              {typeof line === 'string' &&
                <Space space="0 0 15">
                  <P>{line}</P>
                </Space>
              }
              {typeof line === 'object' && line.orderedList &&
                <List list={line.orderedList} ordered />
              }
              {typeof line === 'object' && line.unorderedList &&
                <List list={line.unorderedList} />
              }
              {typeof line === 'object' && line.sections &&
                line.sections.map(lineSection => this.renderSection(lineSection, level + 1))
              }
            </div>
          ))}
        </Space>
      </section>
    );
  }

  render() {
    const today = new Date();
    const year = today.getFullYear();

    return (
      <Fragment>
        <GlobalStyle />
        <Page id="uvod">
          <Header>
            
            <MobileHeader>
              <SiteMenu items={data.sections} />

              <Space space="30 40" spaceMobile="5 0 0">
                <Flex alignCenter notOnMobile>
                  <Logo src={logo} alt={`Logo ${data.siteName}`} />
                  <SiteName>{data.siteName}</SiteName>
                </Flex>
              </Space>
            </MobileHeader>

            <Slogan>
              <P>{data.slogan}</P>
            </Slogan>

            <Flex style={{ position: 'relative', overflow: 'hidden' }} notOnMobile>
              <ClickCounter>
                {({ todayCount, totalCount, animate, increment, disabled }) => (
                  <Space space="20 40" spaceMobile="20 0">
                    <ClickCounterWrapper>
                      <CountTotal>
                        <div>
                          <CountUp start={animate ? 0 : totalCount} end={totalCount} redraw={animate} />
                        </div>
                        <div>{data.clickCounter.totalLabel}</div>
                      </CountTotal>

                      <CountToday>
                        <div>{data.clickCounter.todayLabel}</div>
                        <CountTodayNumber>
                          <CountUp start={animate ? 0 : todayCount} end={todayCount} redraw={animate} />
                        </CountTodayNumber>
                      </CountToday>

                      <span style={{ position: 'relative', display: 'inline-block' }}>
                        <Toggleable>
                          {({ innerRef, isOpened, toggle }) => (
                            <Help ref={innerRef}>
                              <IconInfo onClick={toggle} size={35} dropShadow />

                              {isOpened &&
                              <Overlay onClick={toggle}>
                                <Popup>
                                  <IconClose onClick={toggle} size={22} dropShadow />
                                  <div onClickCapture={e => e.stopPropagation()}>
                                    <P>{data.clickCounter.helpText}</P>
                                  </div>
                                </Popup>
                              </Overlay>
                              }
                            </Help>
                          )}
                        </Toggleable>

                        <ClickButton
                          onClick={increment}
                          color={theme.mainColor}
                          type="button"
                          disabled={disabled}
                        >
                          {data.clickCounter.buttonLabel}
                        </ClickButton>
                      </span>
                    </ClickCounterWrapper>
                  </Space>
                )}
              </ClickCounter>

              <Korunka title={data.korunka.title} content={data.korunka.text} />
            </Flex>

          </Header>

          <Space space="0 40 20" spaceMobile="0 20 20">
            {data.sections.map(section => this.renderSection(section))}
          </Space>
        </Page>

        <Footer>
          <span dangerouslySetInnerHTML={{
            __html: `${data.copyright} ${data.createdYear}${year > data.createdYear ? ` – ${year}` : ''}`
          }} />
          <P>{data.createdBy}</P>
        </Footer>

        <CookiesInfo text={data.cookiesInfo} />
      </Fragment>
    );
  }
}


export default KorunkaNaCestu;
