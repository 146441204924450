import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Hamburger = styled.span`
  position: absolute;
  top: 0;
  
  &:before,
  &:after {
    content: '';
    position: absolute;
  }
  
  ${({ size, color, dropShadow }) => css`
    &:before {
      top: ${size / 3}px;  
    }
    
    &:after {
      top: ${(size / 3) * 2}px;
    }
    
    &,
    &:before,
    &:after {
      display: inline-block;
      width: ${size}px;
      height: ${size / 6}px;
      background: ${color};
      
      ${dropShadow && css`
        box-shadow: 0 0 10px #000;
      `};
    }
  `};
`;

Hamburger.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  dropShadow: PropTypes.bool
};

Hamburger.defaultProps = {
  size: 20,
  color: '#fff',
  dropShadow: false
};

export default Hamburger;
